.login-modal-btn1
  border: 0.1rem solid #1A2786
  margin: 0.5rem 0
  background: #1A2786
  margin-bottom: 1rem
  margin-top: 0.9rem
  cursor: pointer
  @media screen and (min-width: 750px)
    margin-bottom: 10px
    margin-top: 20px
  .modal-btn1
    display: flex
    padding: 0.2rem 0
    justify-content: center
    align-items: center
    @media screen and (min-width: 750px)
      padding: 8px 0
    .modal-text
      margin-right: 1.2rem
      color: white
      line-height: 1.8rem
      font-size: .84rem
      font-weight: bold
      @media screen and (min-width: 750px)
        font-size: 24px
        line-height: 30px
        margin-right: 30px
    .modal-img
      width: 1rem
      height: 1rem
      @media screen and (min-width: 750px)
        width: 30px
        height: 30px
    .modal-img2
      width: 1.25rem
      @media screen and (min-width: 750px)
        width: 40px
.login-modal-btn2
  background: #ffffff
  .modal-btn1
    .modal-text
      color: #1A2786

.mb-login-btn
  margin-top: unset !important
  
.login-btn
  margin-top: 12px !important
  .btn-name
    margin-left: -22px
    font-size: 18px
