.metacity-btn
  font-family: 'Audiowide-Regular'
  font-style: normal
  font-weight: 400
  font-size: 20px
  width: 100%
  text-align: center
  height: 45px
  background: #1a2786
  line-height: 45px
  color: #fff
  margin-top: 10px
  border-radius: 8px
  position: relative
  cursor: pointer
  overflow: hidden
  @media screen and (max-width: 750px)
    border-radius: unset
  // z-index: -1
  .meta-btn-bg
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 0
  .btn-name
    position: relative
    text-align: center
    width: 100%
    z-index: 1
    
