.meo-container
  background: url('../../asset/imgs/myprofile/profilebg.png')
  position: relative
  .container-box
    // width: 1264px
    margin: 0 auto
    margin-top: 88px
    margin-bottom: 32px
    @media screen and (max-width: 750px)
      width: unset
      padding: 1.3867rem .9867rem 1.92rem .9867rem
      margin-top: 3rem
      margin-bottom: .8533rem
    .announcement-info
      @media screen and (max-width: 750px)
        position: fixed
        width: 100%
        background: #E2325C
        z-index: 2
        transition: 1s all
        top: 3rem
        left: 0rem
        padding: .2933rem .4267rem .31rem .4267rem
        .announcement-icon
          display: flex
          justify-content: space-between
          align-items: center
          img
            width: 1.3867rem
            height: 1.3867rem
            margin-right: .6133rem
          .announcement-text
            margin-right: 3rem
            p
              color: #fff
              font-family: 'Poppins'
              &:nth-child(1)
                font-weight: 700
                font-size: .5333rem
                line-height: .8533rem
              &:nth-child(2)
                font-size: .4267rem
                line-height: .5333rem
          .close-icon
            cursor: pointer
            width: .8533rem
            height: .8533rem
            margin-right: 0
    .ore-title
      position: relative
      margin-bottom: 39px
      padding: 0 88px
      padding-top: 18px
      @media screen and (max-width: 750px)
        padding: unset
        padding-top: .6667rem
        margin-bottom: 1.04rem

      img
        width: 100%
        @media screen and (max-width: 750px)
          width: 100%

    .ore-content
      background: #FFFFFF
      border: 2px solid #5A5A77
      width: 676px
      margin: 0 auto
      padding: 32px 32px 40px 32px
      margin-bottom: 48px
      @media screen and (max-width: 750px)
        width: 18.0267rem
        padding: .8533rem .8533rem 1.0667rem .8533rem
        margin-bottom: unset
    

      .select-hain-box
        margin: 16px 0 20px 0
        @media screen and (max-width: 750px)
          margin: .4267rem 0 .8533rem 0

        .select-hain
          margin-bottom: 16px
          display: flex
          flex-direction: column
          @media screen and (max-width: 750px)
            margin-bottom: .2133rem
          .select-title
            p
              font-size: 16px
              line-height: 100%
              margin-left: 16px
              @media screen and (max-width: 750px)
                font-size: .4267rem
                margin-left: .4267rem

      .content-amount
        border: 2px solid #5A5A77
        padding: 12px
        @media screen and (max-width: 750px)
          padding: .4267rem
          margin: .4267rem 0

        .amount-top
          display: flex
          justify-content: space-between
          margin-bottom: 8px
          // position: relative
          @media screen and (max-width: 750px)
            // padding-right: .2933rem
            margin-bottom: .2133rem

          p
            &:nth-child(1)
              font-family: 'Audiowide-Regular'
              font-style: normal
              font-weight: 400
              font-size: 16px
              line-height: 100%
              color: rgba(0, 0, 0, 0.3)
              margin-right: 8px
              @media screen and (max-width: 750px)
                font-size: .4267rem
                margin-right: .2133rem
          .Max-btn
            display: flex
            // position: absolute
            // right: 88px
            // top: 37px
            @media screen and (max-width: 750px)
              // right: 3.5rem
              // top: 1.05rem
            p
              font-family: 'Audiowide-Regular'
              font-weight: 400
              font-size: 16px
              line-height: 100%
              color: rgba(0, 0, 0, 0.3)
              @media screen and (max-width: 750px)
                font-size: .4267rem
        .amount-down
          display: flex
          // padding-right: 30px
          justify-content: space-between
          @media screen and (max-width: 750px)
            // padding-right: 1.3rem
          .tokaulogo
            display: flex
            align-items: center
            p
              @media screen and (max-width: 750px)
                font-size: .8533rem

            img
              width: 32px
              height: 32px
              margin-right: 8px
              @media screen and (max-width: 750px)
                height: .8533rem
                width: .8533rem
      .ore-show
        width: 32px
        margin: 0 auto
        margin-bottom: 4px
        margin-top: 4px
        display: flex
        cursor: pointer
        @media screen and (max-width: 750px)
          width: .8533rem
          margin-bottom: .4267rem
          margin-top: .4267rem
        img
          width: 100%
      .exchange-show
        display: flex
        align-items: center
        justify-content: space-between
        margin: 14px 0 10px 0
        @media screen and (max-width: 750px)
          margin: .8533rem 0

        .exchange-left
          display: flex
          align-items: center

          img
            width: 32px
            @media screen and (max-width: 750px)
              width: .8533rem
          p
            font-family: 'Epilogue-Regular'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 100%
            color: rgba(0, 0, 0, 0.3)
            @media screen and (max-width: 750px)
              font-size: .64rem

        .exchange-right
          display: flex
          p
            font-family: 'Epilogue-Regular'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 100%
            display: flex
            align-items: flex-end
            text-align: right
            color: rgba(0, 0, 0, 0.3)
            @media screen and (max-width: 750px)
              font-size: .64rem

      .content-Code
        border: 2px solid #5A5A77
        padding: 8px 0px 8px 25px
        @media screen and (max-width: 750px)
          padding: .2133rem 0rem .2133rem .6667rem
        p
          font-family: 'Epilogue-Regular'
          font-weight: 700
          font-size: 20px
          line-height: 160%
          margin-bottom: 8px
          @media screen and (max-width: 750px)
            font-size: .5333rem
        .content-Code-usdt
          display: flex
          justify-content: space-between
          padding-right: 30px
          @media screen and (max-width: 750px)
            padding-right: .8rem
          p
            font-family: 'Audiowide-Regular'
            font-weight: 400
            font-size: 24px
            line-height: 120%
            margin-bottom: unset
          @media screen and (max-width: 750px)
            font-size: .7rem

      .ore-btn
        // width: 400px
        font-size: 20px
        line-height: 160%
        margin: 0 auto
        // margin-top: 60px
        margin-bottom: 8px
        @media screen and (max-width: 750px)
          // width: 14.9333rem
          height: 2.3467rem !important
          line-height: 2.5rem !important
          font-size: .7467rem !important
          border-radius: .2133rem
          // margin-top: 1.6rem
          margin-bottom: .5867rem
      .ore-tip
        font-family: 'Audiowide-Regular'
        font-size: 14px
        line-height: 16px
        color: #1A2786
        text-align: center
        @media screen and (max-width: 750px)
          font-size: .3733rem
          line-height: .4267rem
          // padding-left: 1.12rem
    .exchange-code
      margin: 0 auto
      margin-top: 32px
      margin-bottom: 1px
      width: 520px
      @media screen and (max-width: 750px)
        width: 100%
        padding: .8533rem 0rem 1.68rem 0rem
        margin-top: unset
      .back-icon
        position: absolute
        top: 1.7rem
        left: 5.5rem
        width: 40px
        @media screen and (max-width: 750px)
          top: 11rem
          left: 17.5rem
          width: 1.5rem
        img
          width: 100%
      .exchange-code-title
        font-family: "Audiowide-Regular"
        font-size: 24.6152px
        line-height: 120%
        margin-bottom: 12px
        color: #1A2786
        @media screen and (max-width: 750px)
          font-size: .8533rem
          margin-bottom: .4267rem
      .exchange-code-box
        background: #FFFFFF
        border: 4px solid #1A2786
        @media screen and (max-width: 750px)
          border: .1333rem solid #1A2786

        .exchange-code-content
          padding: 49px 25px
          @media screen and (max-width: 750px)
            padding: 1.7067rem .8533rem

          .exchange-code-num
            border: 3px solid #1A2786
            padding: 16px
            margin-bottom: 27px
            @media screen and (max-width: 750px)
              border: .08rem solid #1A2786
              padding: .4267rem
              margin-bottom: .96rem
            p
              line-height: 100%
              &:nth-child(1)
                font-size: 16px
                margin-bottom: 8px
                color: rgba(0, 0, 0, 0.3)
                @media screen and (max-width: 750px)
                  font-size: .4267rem

              &:nth-child(2)
                font-size: 32px
                margin-bottom: 0
                @media screen and (max-width: 750px)
                  font-size: .8533rem
          .exchange-code-meo
            border: 3px solid #1A2786
            padding: 16px
            margin-bottom: 27px
            display: flex
            justify-content: space-between
            @media screen and (max-width: 750px)
              border: .08rem solid #1A2786
              padding: .4267rem
              margin-bottom: .96rem
            p
              font-size: 32px
              line-height: 100%
              margin-bottom: 0
              @media screen and (max-width: 750px)
                font-size: .8533rem
            .exchange-code-meologo
              display: flex
              align-items: center
              img
                width: 32px
                height: 32px
                margin-right: 12px
                @media screen and (max-width: 750px)
                  width: .8533rem
                  height: .8533rem
                  margin-right: .32rem
              p
                font-family: "Audiowide-Regular"
                color: #1A2786
                font-size: 32px
                line-height: 100%
                margin-bottom: 0
                @media screen and (max-width: 750px)
                  font-size: .8533rem
          .exchange-code-des
            font-family: 'Poppins'
            font-size: 18.4614px
            line-height: 31px
            color: rgba(0, 0, 0, 0.3)
            margin-bottom: 27px
            @media screen and (max-width: 750px)
              font-size: .64rem
              line-height: 1.0667rem
              margin-bottom: .96rem
          .exchange-btn  
            height: 60px
            line-height: 60px
            font-size: 20px 
            @media screen and (max-width: 750px)
              height: 2.3467rem !important
              line-height: 2.3467rem !important
              font-size: .8rem !important
          .exchange-code-tip
            font-family: "Audiowide-Regular"
            margin-top: 27px
            font-size: 20px
            line-height: 40px
            color: #E2325C
            @media screen and (max-width: 750px)
              margin-top: .96rem
              font-size: .5333rem
              line-height: 1.0667rem
  .emile-position
    position: absolute
    right: 1.15rem
    top: 2.4rem
    .emile-box
      background: url('../../asset/imgs/meo/joinBox.png') no-repeat
      position: relative
      height: 364.62px
      width: 222.57px
      padding: 128px 21px 84px 21px
      .emile-text

        p
          font-family: 'Poppins'
          font-style: normal
          font-weight: 300
          color: #FFFFFF
          &:nth-child(1)
            font-size: 20px
            line-height: 32px
          &:nth-child(2)
            font-size: 12px
            line-height: 18px
      .emile-btn
        width: 180px
        height: 40px
        line-height: 40px
        font-size: 16px !important
      .cancel-btn
        position: absolute
        cursor: pointer
        top: 10px
        right: 10px
        width: 24px
        height: 24px
        background: url('../../asset/imgs/meo/cancel.png') no-repeat
        background-size: 100% 100%
        img
          width: 100%

  p
    margin-bottom: unset
    font-family: 'Audiowide-Regular'
    font-weight: 400
    font-size: 24px
    line-height: 120%
    color: #2735AF
    @media screen and (max-width: 750px)
      font-size: .8533rem
  .from-input
    .ant-input
      border: unset
      padding: unset
      font-family: 'Audiowide-Regular'
      font-style: normal
      font-weight: 400
      font-size: 24px
      line-height: 100%
      color: #2735AF
      outline: none !important
      @media screen and (max-width: 750px)
        font-size: .8533rem
  .enter-address
    .ant-input
      border: unset
      padding: unset
      font-family: 'Audiowide-Regular'
      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 100%
      color: #2735AF
      outline: none !important
      @media screen and (max-width: 750px)
        font-size: .4267rem
  
  .ant-input::-webkit-inner-spin-button
    -webkit-appearance: none
  .ant-input::-webkit-outer-spin-button
    -webkit-appearance: none
  .swap-spin
    max-height: 100vh !important
    height: 100vh
    position: fixed !important

  .countdown
    position: absolute
    top: 1.4rem
    right: 6.6rem
    @media screen and (max-width: 750px)
      top: 6.5rem
      right: 1.2rem
    .time-box
      display: flex
      align-items: center
      .Ending
        // padding-left: 75px
        margin-right: 19px
        @media screen and (max-width: 750px)
          margin-right: .5067rem

          // padding-left: 1.85rem
        p
          font-family: 'Audiowide-Regular'
          font-style: normal
          font-weight: 400
          font-size: 24px
          line-height: 130%
          color: #2735AF
          @media screen and (max-width: 750px)
            font-size: .64rem
      .time-btn
        display: flex
        .time-day
          display: flex
          margin-right: 6px
          align-items: end
          @media screen and (max-width: 750px)
            margin-right: .16rem

          p
            &:nth-child(1)
              background: #FFFFFF
              box-shadow: 0px 4px 4px rgb(39 53 175)
              border-radius: 3px
              margin-right: 3px
              font-family: 'Audiowide-Regular'
              font-style: normal
              font-weight: 400
              font-size: 15.4201px
              line-height: 130%
              color: #2735AF
              padding: 6px 8px 2px 8px

              @media screen and (max-width: 750px)
                margin-right: .08rem
                font-size: .5333rem
                padding: .16rem .2133rem .0533rem .2133rem
                // margin-bottom: .3rem

            &:nth-child(2)
              font-family: 'Epilogue-Regular'
              font-style: normal
              font-weight: 400
              font-size: 9.25204px
              line-height: 130%
              background: linear-gradient(59.88deg, #3D66A4 47.34%, #7DB490 97.13%)
              -webkit-background-clip: text
              -webkit-text-fill-color: transparent
              background-clip: text
              text-fill-color: transparent
              margin-bottom: -5px
              @media screen and (max-width: 750px)
                font-size: .32rem
                margin-bottom: -0.1333rem

                // margin-right: .4533rem

  .box-detail
    max-width: 1316px
    margin: 0 auto
    // padding: 1rem 2rem
    // margin-top: 20px
    position: relative
    margin-bottom: 87px
    @media screen and (max-width: 750px)
      margin-top: 1rem
    .detail-title
      font-size: 40px
      margin-bottom: 8px
      @media screen and (max-width: 750px)
        font-size: 1.0667rem
        padding: 2.7067rem 0.9867rem 0 0.9867rem
        margin-bottom: .4267rem
    .box-detail-bg
      position: absolute
      left: -64px
      // bottom: -480px
      width: 360px
      height: 360px
      z-index: -1
    .detail-content
      display: flex
      width: 300px
      @media screen and (max-width: 750px)
        width: unset
        padding: 0 .9867rem
      .left-box
        .box-detail-title
          padding: 0 24px
          background-color: #E2325C
          border-radius: 40px
          width: 259px
          height: 32px
          display: flex
          line-height: 32px
          margin-bottom: 18px
          @media screen and (max-width: 750px)
            padding: 0 .64rem
            border-radius: 1.0667rem
            width: 6.9067rem
            height: .9rem
            line-height: .9rem
            margin-bottom: .4267rem
          img
            display: block
            width: 20px
            height: 20px
            margin: 7px 10px 0 0
            @media screen and (max-width: 750px)
              width: .5333rem
              height: .5333rem
              margin: .1867rem .2667rem 0 0
          p
            font-size: 20px
            font-family: Audiowide-Regular
            color: #fff
            @media screen and (max-width: 750px)
              font-size: .5333rem
        .box-detail-desc
          p
            font-family: 'Epilogue-Regular'
            font-style: normal
            font-weight: 400
            font-size: 16px
            line-height: 160%
            @media screen and (max-width: 750px)
              font-size: .64rem
              margin-bottom: 0.9rem
      .right-box
        position: relative
        width: 958px
        height: 588px
        margin-left: 30px
        @media screen and (max-width: 750px)
          height: unset

        .table-bg
          position: absolute
          left: 0
          top: 0
          width: 958px
          height: 588px
          z-index: -1
          @media screen and (max-width: 750px)
            height: unset

        .map-title
          width: 200px
          margin: 25px
          margin-bottom: 5px
          @media screen and (max-width: 750px)
            width: unset
            margin: unset
            margin-bottom: unset
            font-size: .5333rem
            color: #000000
            line-height: 120%
            margin: 0.8rem 0 0.8rem 0.8rem
        .map-table
          width: 950px
          display: flex
          flex-direction: column
          margin-left: 4px
          z-index: 1
          overflow: hidden
          background: #fff
          @media screen and (max-width: 750px)
            margin-left: 0.09rem

          span
            margin: 0 10px
            @media screen and (max-width: 750px)
              margin: 0 .2667rem

            &:nth-child(1)
              margin-left: 25px
              @media screen and (max-width: 750px)
                margin-left: .5rem
          .map-table-label
            display: flex
            height: 60px
            line-height: 60px
            background: #fff

            @media screen and (max-width: 750px)
              height: 1.7067rem
              line-height: 1.7067rem
            span
              font-family: 'Audiowide-Regular'
              display: inline-block
              color: #1A2786
              font-size: 16px
              width: 100px
              @media screen and (max-width: 750px)
                font-size: .4267rem
                // width: 2.6667rem
              &:nth-child(1)
                width: 300px
                @media screen and (max-width: 750px)
                  width: 28rem
          .map-table-value
            height: 56px
            display: flex
            line-height: 70px
            // color: #fff
            @media screen and (max-width: 750px)
              display: block
              height: 1.6067rem
              line-height: 1.6067rem
            &:nth-child(2n)
              background: #61A05A
            &:nth-child(2n + 1)
              background: #84CC75
            span
              display: inline-block
              font-size: 16px
              width: 100px
              @media screen and (max-width: 750px)
                font-size: .4267rem
              &:nth-child(1)
                width: 300px
                @media screen and (max-width: 750px)
                  width: 28rem
            .map-table-value
          .order-map-table-value
            height: 70px

          @media screen and (max-width: 750px)
            .mb-hash
              height: unset
              padding-top: 0.3rem

              .Hash-img
                width: 15%
                margin: 0.3rem
                span
                  margin-left: 0 !important
              .Hash-data
                display: flex
                flex-direction: column
                // width: 95%
                margin-top: 0.3rem
                margin-bottom: 0.3rem
                justify-content: space-between
                div
                  display: flex
                  justify-content: space-between
                  align-items: center
                  span
                    padding-left: .5rem
                    // padding-right: .5rem
                    // margin-top: .2133rem
                    &:nth-child(2n-1)
                      color: #fff
                      font-weight: 400
                      font-size: .5333rem
                      line-height: 1.0667rem
                      font-family: 'Audiowide-Regular'
                      margin-left: 0
                      width: unset
                    &:nth-child(2n)
                      font-style: normal
                      font-weight: 400
                      font-size: .64rem
                      line-height: 1.0667rem
                      width: 10rem
                      margin-left: 0
                    // &:nth-child(4)
                    //   overflow: hidden
                    //   white-space: nowrap
                    //   text-overflow: ellipsis

      .ian-right-box
        border: 4px solid #1a2786
        height: 300px
        width: 900px
        // overflow: hidden
        @media screen and (max-width: 750px)
          height: 7rem
        .map-table
          width: 886px
          @media screen and (max-width: 750px)
            margin-left: 0

      @media screen and (max-width: 750px)
        flex-direction: column
        .right-box
          width: unset
          margin-left: 0
          @media screen and (max-width: 750px)
            margin-top: 0.3rem
            margin-bottom: -1rem
          .table-bg
            width: 18.0267rem
          .map-table
            width: 17.65rem
  .ant-radio-group-solid
    // display: flex
    .ant-radio-button-wrapper
      width: 30%
      margin: 1.65%
      margin-bottom: 0px
      text-align: center
      font-family: 'Audiowide-Regular'
      border-radius: 8px
      height: 42px
      line-height: 42px
      border: none
      border: 2px solid #2735AF
      // color: #2735AF
      padding: 0
      @media screen and (max-width: 750px)
        border-radius: .2133rem
        height: 1.5rem
        line-height: 1.2rem
      span
        @media screen and (max-width: 750px)
          font-size: .3733rem
        img
          width: 30px
          height: 30px
          margin-top: -6px
          margin-right: 6px
          @media screen and (max-width: 750px)
            width: .8533rem
            height: .8533rem
            margin-top: -0.16rem
            margin-right: .2133rem
      &::before
        background: none
      &:hover
        color: #2735AF
    .ant-radio-button-wrapper-checked
      background: #1D2781 !important
      border: 2px solid #2735AF !important
      color: #fff !important

  .history-transaction
    .detail-content
      width: auto
      .right-box
        border: 4px solid #1a2786
        background-size: 100% 100%
        width: 1316px
        margin: 0
        height: auto
        @media screen and (max-width: 750px)
          width: unset
        .table-bg
          width: 1316px
        .map-table
          background: url('../../asset/imgs/map/hashTable.png')
          background-repeat: repeat-y
          width: 1308px
          margin: 0
          @media screen and (max-width: 750px)
            width: 100%
            // margin-top: 2.2667rem
            .hash-title
              font-style: normal
              font-weight: 400
              font-size: .5333rem
              line-height: 120%
              font-family: 'Audiowide-Regular'
              padding: .84rem .8533rem
              // .map-table-value
              //   &:nth-child(2n)
              //     background: #61A05A
              //   &:nth-child(2n + 1)
              //     background: #84CC75
          .map-table-label
            span
              &:nth-child(1)
                width: 120px
              &:nth-child(5)
                width: 180px
              &:nth-child(6)
                width: 180px
                font-size: 15px
              &:nth-child(4)
                width: 180px
          .map-table-value
            span
              img
                width: 50px
              &:nth-child(1)
                width: 120px
              &:nth-child(5)
                width: 180px
              &:nth-child(6)
                width: 180px
                font-size: 15px
                a
                  color: blue
                  text-decoration: underline
                  &:hover
                    font-weight: bold
              &:nth-child(9)
                width: 250px
                font-size: 15px
                a
                  color: blue
                  text-decoration: underline
                  &:hover
                    font-weight: bold
              &:nth-child(4)
                width: 180px
              .status-minting
                width: 20px
                margin-left: 25px
                animation: circle infinite 2s linear
                @media screen and (max-width: 750px)
                  margin-top: .5rem
              @keyframes circle
                0%
                  transform: rotate(0)

                100%
                  transform: rotate(360deg)
                  // @media screen and (max-width: 750px)
                  //   .mb-hash
                  //     height: 10rem
          .minting-BG
            background: #A1DEAE !important

    .ant-btn
      border-radius: unset !important
      border-color: unset !important
      transition: unset !important
      padding: unset !important
    .ant-btn-primary
      background: unset !important
      box-shadow: unset !important
    .ant-btn-primary
      &:hover
        background: unset !important
        // border-color: #40a9ff !important
        color: #fff !important
        box-shadow: #fff !important
    .ant-space
      height: 10px
    .ant-space-item
      height: 0.75rem
      line-height: 0rem
      @media screen and (max-width: 750px)
        height: 1.7rem
        line-height: 0rem
  .ant-drawer-title
    font-family: 'Poppins'
    font-style: normal
    font-weight: 700
    font-size: 24px
    line-height: 36px
    color: rgba(0, 0, 0, 0.88)
    @media screen and (max-width: 750px)
      font-size: .64rem
      line-height: .96rem
  .ant-drawer-header
    padding: 24px 400px
    @media screen and (max-width: 750px)
      padding: .8533rem .8533rem

  .ant-drawer-body
    padding: 8px 400px
    @media screen and (max-width: 750px)
      padding: 0 .8533rem
  .Drawer-text
    display: flex
    align-items: center
    margin: 24px 0
    @media screen and (max-width: 750px)
      margin: 1.0667rem 0

    img
      width: 24px
      height: 24px
      margin-right: 8px
      @media screen and (max-width: 750px)
        width: 1rem
        height: 1rem
        margin-right: .3rem
    p
      font-family: 'Epilogue-Regular'
      font-style: normal
      font-weight: 700
      font-size: 21px
      line-height: 100%
      color: rgba(0, 0, 0, 0.88)
      @media screen and (max-width: 750px)
        font-size: .7467rem
  .ant-drawer-close
    padding: 30px 400px
    @media screen and (max-width: 750px)
      padding: .8rem 0.6667rem
