@font-face
  font-family: 'Epilogue-Black'
  src: local('Epilogue-Black'), url(/asset/font/Epilogue-text/Epilogue-Black.ttf) format('truetype')
@font-face
  font-family: 'Epilogue-Bold'
  src: local('Epilogue-Bold'), url(/asset/font/Epilogue-text/Epilogue-Bold.ttf) format('truetype')
@font-face
  font-family: 'Epilogue-Medium'
  src: local('Epilogue-Medium'), url(/asset/font/Epilogue-text/Epilogue-Medium.ttf) format('truetype')
@font-face
  font-family: '1'
  src: local('Epilogue-Regular'), url(/asset/font/Epilogue-text/Epilogue-Regular.ttf) format('truetype')
@font-face
  font-family: 'Epilogue-Thin'
  src: local('Epilogue-Thin'), url(/asset/font/Epilogue-text/Epilogue-Thin.ttf) format('truetype')
@font-face
  font-family: 'Audiowide-Regular'
  src: local('Audiowide-Regular'), url(/asset/font/Audiowidetitle/Audiowide-Regular.ttf) format('truetype')

  body
    margin: 0
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace

html,
body
  font-size: 5vw !important
  margin: 0
  padding: 0
  box-sizing: border-box
  #root
    min-height: 100vh !important
ul,
li
  list-style-type: none

input
  outline: none
  border-radius: 0px
  border: 0 none

.switch-name
  background: #2634aa !important
  border-color: #2634aa !important

.walletconnect-connect__button__text
  font-size: 16px !important
  @media screen and (max-width: 750px)
    font-size: .4267rem !important

.am-modal-mask,.am-modal-wrap
  z-index: 20000000 !important
  // 16110260

.ant-notification
  z-index: 20000001 !important

.process-icon
  display: flex
  width: 24px
  height: 24px
  animation: circle infinite 3s linear
  margin-top: 10px
  @keyframes circle
    0%
      transform: rotate(0)

    100%
      transform: rotate(360deg)
  @media screen and (max-width: 750px)
    width: .8533rem
    height: .8533rem
    margin-top: .2667rem
  img
    width: 24px
    height: 24px
    @media screen and (max-width: 750px)
      width: .8533rem
      height: .8533rem
