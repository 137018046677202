.app-wrapper {
  width: 100vw;
  min-height: 100vh;
  /* min-height: calc(100vh - 3rem); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 3rem; */
  background: url('./asset/imgs/myprofile/profilebg.png')
}

 .app-wrapper .loading-box p {
  font-size: 30px;
  margin-top: 20px;
}


.app-wrapper .loading-box .loading {
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-top-color: transparent;
  border-radius: 100%;
  animation: circle infinite 0.75s linear;
  margin-left: 36px;
}
/*
.app-wrapper .loading-box .loading2 {
  margin-left: 60px;
}

.app-wrapper .loading-box .loading:before,
.app-wrapper .loading-box .loading:after {
  position: absolute;
  width: 20px;
  height: 20px;
  content: "";
  animation: jumping 0.5s infinite alternate;
  background: rgba(0, 0, 0, 0);
}

.app-wrapper .loading-box .loading:before {
  left: 0;
}

.app-wrapper .loading-box .loading:after {
  right: 0;
  animation-delay: 0.15s;
} */

/* @keyframes jumping {
  0% {
    transform: scale(1) translateY(0px) rotateX(0deg);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1.2) translateY(-25px) rotateX(45deg);
    background: #000;
    box-shadow: 0 25px 40px #000;
  }
} */

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 750px) {
  .app-wrapper {
    min-height: 100vh;
    /* min-height: calc(100vh - 94px); */
    /* margin-top: 94px; */
  }
}