body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins !important;
  font-style: normal;
}

/* body{
  height: 100%;
   width: 100%;
   position: fixed;
   top: 0;
   left: 0;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: absolute;
  width: 100%;
  /* padding: 3.5rem 0; */
  top: 0;
}

.am-modal-content {
  background-color: #23262F !important;
  padding: 0;

}

.am-modal-title {
  color: #FCFCFD !important;
  height: 1.5rem;
  line-height: 6.5rem;
  padding-top: 2rem;
  font-family: Poppins;
  font-style: Bold;
  font-size: 1.2rem !important;
}

.am-modal-mask {
  background: rgba(0, 0, 0, 0.75) !important;
}

.am-list {
  background-color: #23262F !important;
}

.am-list-item.am-radio-item.am-list-item-middle {
  background-color: #23262F !important;
  color: white;
  border: none !important;
}

.am-radio-input {
  color: #fff;
}