.mobile-menu
  position: fixed
  bottom: .48rem
  left: .8533rem
  z-index: 9
  // z-index: 20000000
  // opacity: 0.4
  .mobile-menu-mask
    .mobile-menu-list
      position: absolute
      bottom: .4667rem
      left: .8533rem
      div
        font-family: 'Epilogue-Bold'
        font-style: normal
        font-weight: 500
        font-size: .7467rem
        line-height: 2rem
        color: #fff
        padding-left: 0.2rem
      .active-menu
        color: red
  img
    width: 2.65rem
    height: 2.65rem

@media screen and (min-width: 750px)
  .mobile-menu
    display: none
