.header-container {
  width: 100vw;
  height: 3rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  // background: #fff;
  // opacity: 0.8;
  // box-shadow: 0px -12px 83px #d3d9f0;
  // -webkit-mask-image: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0) -2%, rgba(255, 255, 255, 1) 13%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  .mobile-menu-btn {
    position: fixed;
    bottom: .48rem;
    left: .8533rem;
    z-index: 99;

    img {
      width: 2.65rem;
      height: 2.65rem;
    }
  }

  .header-logo {
    margin-left: .5333rem;
    width: 5.3333rem;
    height: 1.3867rem;
    background: url('../../assets/imgs/logo_blue.png') no-repeat;
    background-size: contain;
  }

  .icon {
    display: inline-block;
    background: rgba(121, 121, 121);
    width: 1.3067rem;
    height: 1.36rem;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.28rem;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    // margin: 0.5rem;
  }

  .back-btn {
    margin-left: 1.2rem;
    background-image: url('../../assets/imgs/return.png');
  }

  .uglobe {
    position: absolute;
    right: 8.2rem;
    // background: url('../../assets/imgs/u_globe_white.png') no-repeat;
    background: url('../../assets/imgs/uglobe.png') no-repeat;
    background-size: contain;
    z-index: 999;
  }

  .switch-btn {
    background: rgba(62, 62, 77, 0.52);
    border-radius: 0.3rem;
    color: #fff;
    padding: 0 .425rem;
    font-size: 0.7rem;
    cursor: pointer;
  }

  .user-addr {
    letter-spacing: -0.01rem;
    display: inline-block;
    width: 6.6667rem;
    height: 1.7333rem;
    line-height: 1.12rem;
    // background: #fff;
    font-size: .64rem;
    // color: #2735AF;
    background: #2735AF;
    color: #fff;
    margin-right: .5333rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 0.9rem;
      display: inline-block;
      margin: 0.2rem;
    }

    span {
      display: inline-block;
      height: 1.12rem;
    }
  }
}

.uglobe-drop-down {
  position: fixed !important;
  z-index: 100
}

#uglobe {
  >div {
    z-index: 99;
  }
}

.account-modal {
  position: relative;
  // margin-bottom: 5rem;

  .account-des {
    margin-top: 1rem;
    text-align: left;
  }

  .account-info {
    display: flex;
    align-items: center;
    justify-content: center;

    .account-avatar {
      width: 1.7067rem;

      img {
        width: 1.7067rem;
      }
    }

    .account-address {
      width: calc(100% - 1.7067rem);
      line-height: .64rem;
      word-wrap: break-word;
      word-break: break-all;
      font-size: .64rem;
      text-align: left;
      padding-left: 0.6rem;
      font-weight: 350;
      font-family: 'Noto Sans JP';
      margin: 1.6rem 0;

      .ant-typography {
        margin-bottom: 0;
      }
    }

    .my-modal-button {
      width: 4rem;
      margin: 0;
      font-size: 0.7rem;
      height: 1.4rem;
      line-height: 1.4rem;
    }

    .account-footer {
      font-size: 12px;
    }
  }

  .account-btn {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    justify-content: space-between;

    .icon-and-btn {
      width: 50%;
      color: #1A2786;
      font-size: 0.6rem;
      line-height: 1rem;
      font-weight: bold;

      img {
        width: 1.2rem;
      }
    }
  }

  .account-footer {
    margin-top: 1rem;
    font-size: 10px;
    color: #C4C6CB;
    text-align: left;
  }
}

.ant-dropdown {
  position: fixed;
  top: 70px !important
}

@media screen and (max-width: 750px) {
  .ant-dropdown {
    top: 2.1rem !important
  }
}

.ant-dropdown-menu-item {
  font-family: 'Audiowide-Regular'
}

.ant-menu-submenu .ant-menu-sub .ant-menu-submenu-vertical .ant-menu-submenu-title {
  .ant-menu-title-content {
    font-family: 'Audiowide-Regular';
    color: #2735ae
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

}


@media screen and (min-width: 750px) {

  .pc-header-white {
    left: 0;
    top: 0;
    overflow: hidden;
    position: fixed;
    z-index: 19999999;
    width: 100%;
    display: flex;
    height: 71px;
    justify-content: space-between;
    align-items: center;


    .pc-menu-container {
      cursor: pointer;
      width: 100%;
      // width: calc(100% - 826px);
      background: transparent;
      border: none;
      justify-content: center;

      .sub-menu {
        height: 40px;
        line-height: 40px;
        align-items: center;
        font-family: 'Epilogue-Regular';
        font-style: normal;
        padding: 0 10px;

        .ant-menu-submenu-title {
          height: 40px;

          span {
            display: inline-block;
            text-align: center;
            color: #ffffff;
            font-family: Audiowide-Regular;
            font-style: normal;
            padding: 0 10px;
          }

        }

        .ant-menu-title-content {
          display: inline-block;
          text-align: center;
          color: #ffffff;

        }
      }

      .active-sub-menu {
        .ant-menu-submenu-title {
          span {
            color: red;
          }

          .ant-menu-title-content {
            color: red;
          }
        }
      }

      .center-nav-item {
        border-left: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
      }
    }

    .pc-header-logo {
      margin-left: 20px;
      width: 200px;
      height: 40px;
      background: url('../../assets/imgs/logo.png') no-repeat;
      background-size: contain;
      cursor: pointer;
    }

    .pc-header-btn {
      display: flex;
      height: 94px;
      align-items: center;

      .switch-btn {
        cursor: pointer;
        background: #fff;
        border-radius: 0.3rem;
        color: #2735AF;
        padding: 0 10px;
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        margin-right: 12px;
        font-family: 'Epilogue-Regular';
      }

      .pc-connect-btn {
        font-family: 'Audiowide-Regular';
        cursor: pointer;
        background: #ffffff;
        width: 216px;
        height: 40px;
        color: #2735AF;
        line-height: 40px;
        font-style: normal;
        font-weight: normal;
        text-align: center;
        margin-right: 40px;
        font-size: 18px;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      .pc-uglobe {
        margin-right: 12px;
        cursor: pointer;
        display: block;
        width: 32px;
        height: 32px;
        background: url('../../assets/imgs/u_globe_white.png') no-repeat;
        background-size: contain;
      }
    }

  }

  .blue-header {
    background: #ffffff;

    .pc-menu-container {
      .sub-menu {
        .ant-menu-submenu-title {
          span {
            color: #2735AF;
          }
        }
      }

      .active-sub-menu {
        .ant-menu-submenu-title {
          span {
            color: red;
          }
        }
      }
    }

    .pc-header-logo {
      background: url('../../assets/imgs/logo_blue.png') no-repeat;
      background-size: contain;
    }

    .pc-header-btn {
      .switch-btn {
        background: #2735AF;
        color: #ffffff;
      }

      .pc-connect-btn {
        background: #2735AF;
        color: #ffffff;
      }

      .pc-uglobe {
        background: url('../../assets/imgs/uglobe.png') no-repeat;
        background-size: contain;
      }
    }
  }

  .account-modal {
    position: relative;
    // margin-bottom: 5rem;

    .account-des {
      margin-top: 54px !important;
      text-align: left;
      font-size: 20px !important;
      color: #000;
      line-height: 23px;
      font-weight: 500;
      font-family: 'Poppins'
    }

    .account-info {
      display: flex;
      align-items: center;
      justify-content: center;

      .account-avatar {
        width: 48px;

        img {
          width: 48px;
        }
      }

      .account-address {
        width: calc(100% - 75px);
        line-height: 18px;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 12px;
        text-align: left;
        padding-left: 22.5px;
        margin: 24px 0;
        padding-top: 10px;

        .ant-typography-copy {
          img {
            width: 32px !important;
            margin-bottom: 6px;
          }
        }
      }

      .my-modal-button {
        width: 4rem;
        margin: 0;
        font-size: 0.7rem;
        height: 1.4rem;
        line-height: 1.4rem;
      }

      .account-footer {
        font-size: 12px;
      }
    }

    .account-btn {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 24px;
      margin-top: 0;

      .icon-and-btn {
        cursor: pointer;
        width: 40%;
        font-size: 18px;
        line-height: 27px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;

        img {
          width: 24px !important;
          cursor: pointer;
          margin-top: -3px;
          margin-right: 6px;
        }
      }
    }

    .account-footer {
      margin-top: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #C4C6CB;
      margin-bottom: 24px;
    }
  }
}

// 使用css预加载图片
// .preload-img {
//   visibility: hidden;
//   background:
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Snoop+Dogg.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Takahashi+Syoko.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Amatsuka+Moe.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Mikami+Yua.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Olivia.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Chuck+D.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Ian+Somerhalder.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Luna+Maya.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Russell+Simmons.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/MasterMinds+of+HipHop.png') no-repeat,
//     url('https://tokau.s3.us-east-2.amazonaws.com/celebrity/Allen+Iverson.png') no-repeat,
//   ;
// }
.ant-menu-submenu {
  .ant-menu-sub {
    .active-sub-menu {
      .ant-menu-submenu-title {
        span {
          color: red;
        }

        .ant-menu-title-content {
          color: red;
        }
      }
    }
  }
}