.footer-color
  background: #1b326d
  padding: 60px 0px 34px 0px
  @media screen and (max-width: 750px)
    padding: 1.6rem 0px .9067rem 0px
  .meta-footer
    padding-bottom: 34px
    font-size: 16px
    width: 1264px
    margin: 0 auto
    color: #fff
    @media screen and (max-width: 750px)
      padding: 0 1.067rem .9067rem 1.067rem
      font-size: .4267rem
      width: unset
    .footer-col-one
      border-bottom: 1px solid #74aabc
      div
        &:nth-child(1)
          margin-bottom: 30px
          text-align: center
          @media screen and (max-width: 750px)
            margin-bottom: 0.8rem
          img
            width: 200px
            @media screen and (max-width: 750px)
              width: 5.3333rem
        &:nth-child(2)
          text-align: center
          margin-bottom: -25px
          @media screen and (max-width: 750px)
            margin-bottom: unset
          img
            width: 40px
            margin-right: 35px
            cursor: pointer
            &:nth-child(5)
              margin-right: unset
            @media screen and (max-width: 750px)
              width: 2.032rem
              margin-right: 1.8rem
              &:nth-child(5)
                margin-right: unset
        &:nth-child(3)
          img
            width: 200px
            display: block
            @media screen and (max-width: 750px)
              width: 5.3333rem
              margin: 0 auto
              margin-top: 1rem
  .footer-tar      
    font-size: 14px
    text-align: center
    color: #fff
    font-family: Epilogue-Thin
    @media screen and (max-width: 750px)
      font-size: .3733rem
      font-family: 'Epilogue-Regular'

  