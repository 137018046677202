.my-modal-container {
    background: #fff !important;
    border-radius: 1.2rem;
    z-index: 100;
    width: 16.27rem !important;
    height: auto !important;
    max-height: calc(100vh - 1rem);

    .am-modal-content {
        background: #fff !important;
        padding: 1.0933rem;
        position: relative;
        overflow: unset;

        .two-modal-button {
            width: 100%;
            overflow: hidden;
            margin-top: 0.5rem;
        }

        .my-modal-button {
            width: 100%;
            height: 2.13rem;
            line-height: 2.13rem;
            background: #595976;
            box-shadow: 0px 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
            border-radius: 1rem;
            margin-top: 1.33rem;
            color: #fff;
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 0.8rem;
            text-align: center;
        }

        .am-modal-close {
            right: calc(50% - 7.3rem);
            width: 1rem;
            height: 1rem;
            top: 1.5333rem;
            z-index: 100;
            // background-color: #fff;

            .am-modal-close-x {
                width: 1rem;
                height: 1rem;
                background: url('../../asset/imgs/Modal/Close.png') no-repeat;
                background-size: 70% 70%;
            }
        }

        .am-modal-body {
            padding: 0;
            overflow: hidden;
        }

        .my-modal-content {
            margin-top: 0;

            .sn-list {
                margin: 0;
                padding: 0.4rem;
                display: flex;
                justify-content: space-between;
                font-size: 0.6rem;
                align-items: center;

                img {
                    width: 1rem;
                    height: 1rem;
                }

                .animate-img {
                    animation: rotate 3s linear infinite;
                }

                @keyframes rotate {
                    0% {
                        transform: rotateZ(0deg);
                    }

                    100% {
                        transform: rotateZ(360deg);
                    }
                }
            }


            .am-list {
                background: #fff !important;

                .am-list-body {
                    background: #fff !important;

                    &::after {
                        background: none !important;
                    }

                    &::before {
                        background: none;
                    }

                    .am-list-item {
                        background: #fff !important;
                        color: #000;
                        padding-left: 0;

                        .am-list-line {
                            &::after {
                                background: none !important;
                            }
                        }
                    }

                }
            }

            .modal-title {
                font-family: Poppins;
                font-weight: 700;
                font-size: 1.3333rem;
                line-height: 1.7067rem;
                letter-spacing: -0.01em;
                color: #000;
                padding: 0;
                text-align: left;
            }

            .account-modal {
                .account-des {
                    font-size: .64rem;
                    margin-top: 1.8133rem;
                    margin-bottom: 0;
                }
            }

            .item-title {
                margin-top: 1rem;

                .item-title-icon {
                    float: left;

                    .animate-img {
                        animation: rotate 3s linear infinite;
                    }

                    @keyframes rotate {
                        0% {
                            transform: rotateZ(0deg);
                        }

                        100% {
                            transform: rotateZ(360deg);
                        }
                    }

                    img {
                        width: 2.13rem;
                        height: 2.13rem;
                    }

                    margin-right: 0.5rem;
                }

                .item-title-right {
                    padding-left: 0.6rem;
                    float: left;
                    width: 9rem;
                    font-size: 0.64rem;
                    line-height: 1.05rem;
                    text-align: left;

                    P {
                        padding: 0;
                        margin: 0;

                    }

                    .item-title-right-top {
                        font-weight: bold;
                        padding-bottom: 0.4rem;
                        color: #5B5B5B;
                    }

                    .item-title-right-bottom {
                        font-weight: normal;
                        color: #8F8F8F;
                    }
                }
            }

            .am-list {
                width: 11rem;
                clear: both;
                text-align: left;
            }

            button {
                width: 100%;
                height: 2.13rem;
                line-height: 2.13rem;
                background: #595976;
                box-shadow: 0px 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
                border-radius: 1rem;
                margin-top: 1.33rem;
                color: #fff;
                font-family: Poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (min-width: 750px) {
    .my-modal-container {
        width: 480px !important;

        .am-modal-content {
            padding: 30px 60px;

            .two-modal-button {
                width: 100%;
                overflow: hidden;
                margin-top: 18.75px;
            }

            .my-modal-button {
                width: 100%;
                height: 2.13rem;
                line-height: 2.13rem;
                background: #595976;
                box-shadow: 0px 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
                border-radius: 1rem;
                margin-top: 1.33rem;
                color: #fff;
                font-family: Poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 0.8rem;
                text-align: center;
            }

            .am-modal-close {
                right: calc(50% - 195px);
                width: 30px;
                height: 30px;
                top: 41px;
                z-index: 100;
                border-radius: 50%;

                .am-modal-close-x {
                    width: 25px;
                    height: 25px;
                    background: url('../../asset/imgs/Modal/Close.png') no-repeat;
                    background-size: 70% 70%;
                    display: block;
                }
            }

            .my-modal-content {

                .am-list {
                    // padding-top: 25px !important;
                    padding-bottom: 25px !important;
                }

                .modal-title {
                    font-family: Poppins;
                    font-weight: 700;
                    font-size: 24px;
                    color: #000;
                    padding: 0;
                    text-align: left;
                    line-height: 36px;
                }


                .item-title {
                    .item-title-right-bottom {
                        font-size: 16px;
                    }
                }

                button {
                    width: 100%;
                    height: 65px;
                    line-height: 70px;
                    background: #5a5a77;
                    box-shadow: unset;
                    border-radius: 28px;
                    margin-top: unset;
                    font-weight: bold;
                    font-size: 30px;
                    margin-top: 20px;
                }
            }

        }
    }
}